import configJson from "./auth_config.json"

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download t51ool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  ///const APIURL="https://localhost:7225/";
  //const APIURL = "https://localhost:7154/"
  const APIURL = "./";
    
  const audience =
    configJson.audience && configJson.audience !== "YOUR_API_IDENTIFIER"
      ? configJson.audience
      : null

  return {
    APIURL: APIURL,
    domain: configJson.domain,
    clientId: configJson.clientId,
    clientSecret: configJson.clientSecret,
    Token: configJson.Token,
    ...(audience ? { audience } : null),
  }
}
